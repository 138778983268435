import React, { Component, Fragment, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FetchDataState, dataState, tabsData, selectedTags, selectedCompanies } from './recoil/atoms/state';

//コンポーネント
import Tab from './components/tab';
import SearchTags from './components/searchtags';
import Accordions from './components/accordion';
import Selected from './components/selected';
import Result from './components/result';


const Search = () => {

    const [fetchData, setFetchData] = useRecoilState(FetchDataState);
    // const [companies, setCompanies] = useRecoilState(selectedCompanies);
    const selectedTag = useRecoilValue(selectedTags)
    let selectedCompanies = []


    // 検索結果を入れるstate
    const [result, setResult] = useState([])

    useEffect(() => {
        fetch('https://summit-labbase-prod.s3.ap-northeast-1.amazonaws.com/json/config.json?'+Date.now()) // production
        // fetch('https://summit-labbase-dev.s3.ap-northeast-1.amazonaws.com/json/config.json?'+Date.now()) // dev
            .then((res) => res.json())
            .then((data) => {
                const filepath = data[1][2];

                fetch(filepath)
                    .then((response) => response.json())
                    .then((responseData) => {
                        setFetchData(responseData.data);
                    })
                .catch((error) => {
                    //fetch自体が失敗したとき
                    console.log("取得に失敗しました。" + "error: " + error);
                })
            })
            .catch((error) => {
                //fetch自体が失敗したとき
                console.log("取得に失敗しました。" + "error: " + error);
            })
    }, [])



    fetchData.companies?.forEach((value, index) => {
        value.hashtags?.forEach((v, i) => {
            selectedTag.forEach((_v) => {
                if (_v === String(v.id)) {
                    let match = false
                    selectedCompanies.find(element => {
                        if(element.id === value.id) {
                            match = true
                        }
                    })

                    if ( ! match) {
                        selectedCompanies.push(value)
                    }
                }
            })
        })
    })

    // タグマスターから名前を検索
    const searchName = (key, list) => {
        let name = null
        list.forEach(object => {
            if(object.id === parseInt(key)) {
                name = object.name
            }
        });
        return name
        // console.log(name);
    }

    // タグマスターを作成
    let flat_tags = []
    fetchData.tabs?.map((value) => {
        flat_tags = flat_tags.concat(value.hashtags);
    })

    // 検索ボタンonclick 結果の表示
    const showResult = () => {
        setResult(selectedCompanies);

        // tagのidリストから和名を検索
        let tagnames = []
        selectedTag.map((item, key) => {
            tagnames.push(searchName(item, flat_tags))
        })
        
        // console.log(tagnames);
        // console.log(tagnames.length);
        
        if(tagnames.length > 0){
            
            // GAへ送信
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'clickSearchHashTags',
                hashTags: tagnames,
                tagLength: tagnames.length,
            })
            tagnames.forEach((tagname) => {
                window.dataLayer.push({
                    event: 'searchHashTag',
                    hashTag: tagname,
                })
            })
            // console.log(window.dataLayer);
            
        }
    }

    return (

        <>
            <div className="search_contents">
                {/* PCのときの表示 */}
                <Tab />

                <div className="search_main">
                    <SearchTags />

                    {/* SPのときの表示 */}
                    <Accordions />

                    <Selected />

                    <div className="search_contents_foot">
                        <p>あなたに合う企業は<span>{selectedCompanies.length}</span>社あります！</p>
                        <a href="#result-jun" className="btn_search" onClick={showResult}>検索する</a>
                    </div>
                </div>
            </div>

            <Result result={result} selectedTag={selectedTag} url="jun" />
        </>

    )

}

export default Search;
