import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

//コンポーネント
import SearchJun from './search-jun';


const App = props => {

    return (
        
        <RecoilRoot>
            <SearchJun />
        </RecoilRoot>
        
    )

}

//レンダリング
const container = document.getElementById('search-jun');
const root = createRoot(container);
root.render(<App />);
